import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

// Styles
import styles from "./team.module.sass"

// Components
import StyledCard from "../../Global/StyledCard/StyledCard"

// Icons
import FacebookIcon from "../../../icons/aboutUsPage/team/facebook.svg"
import LinkedInIcon from "../../../icons/aboutUsPage/team/linkedin.svg"

const teamArr = [
  {
    id: 0,
    name: `Matvii Sivoraksha`,
    title: `CEO`,
    facebook: `https://www.facebook.com/matvey.sivoraksha`,
    linkedin: `https://www.linkedin.com/in/matvii-sivoraksha/`,
  },
  {
    id: 1,
    name: `Korney Vasilchenko`,
    title: `CTO`,
    facebook: `https://www.facebook.com/korneyv`,
    linkedin: `https://www.linkedin.com/in/korney-vasilchenko/`,
  },
  {
    id: 2,
    name: `Vlad Horilyi`,
    title: `Business Developer`,
    facebook: `https://www.facebook.com/horilyi`,
    linkedin: `https://www.linkedin.com/in/vlad-horilyi/`,
  },
  {
    id: 3,
    name: `Julian Konchunas`,
    title: `Blockchain Engineering Manager`,
    facebook: `https://www.facebook.com/konchunas`,
    linkedin: `https://www.linkedin.com/in/konchunas/`,
  },
  {
    id: 4,
    name: `Anastasii Kondaurova`,
    title: `Blockchain Developer`,
    facebook: `https://www.facebook.com/kstasi`,
    linkedin: `https://www.linkedin.com/in/kstasi/`,
  },
  {
    id: 5,
    name: `Sergey Makov`,
    title: `Back-end Developer`,
    facebook: `https://www.facebook.com/smak0v`,
    linkedin: `https://www.linkedin.com/in/sergey-makov-it/`,
  },
  {
    id: 6,
    name: `Ilya Likhoshva`,
    title: `Back-end Developer`,
    facebook: `https://www.facebook.com/IlLik.2010`,
    linkedin: `https://www.linkedin.com/in/ilyalikhoshva/`,
  },
  {
    id: 7,
    name: `Serhii Pashchenko`,
    title: `Full-Stack Developer`,
    facebook: `https://www.facebook.com/serh11p`,
    linkedin: `https://www.linkedin.com/in/serhii-pashchenko-9b9635171/`,
  },
  {
    id: 8,
    name: `Sophia Gorban`,
    title: `Operations Manager`,
    facebook: `https://www.facebook.com/gorbansophia`,
    linkedin: `https://www.linkedin.com/in/sophia-gorban/`,
  },
  {
    id: 9,
    name: `Sergey Glushkovsky`,
    title: `Senior Blockchain Engineer`,
    facebook: `https://www.facebook.com/virdvird`,
  },
]

const TeamSection = () => {
  const data = useStaticQuery(graphql`
    query {
      Matvii_Sivoraksha: file(relativePath: { eq: "Matvii_Sivoraksha.jpeg" }) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Korney_Vasilchenko: file(
        relativePath: { eq: "team/Korney_Vasilchenko.jpeg" }
      ) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Vlad_Horilyi: file(relativePath: { eq: "team/Vlad_Horilyi.jpeg" }) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Julian_Konchunas: file(
        relativePath: { eq: "team/Julian_Konchunas.jpeg" }
      ) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Anastasii_Kondaurova: file(
        relativePath: { eq: "team/Anastasii_Kondaurova.jpeg" }
      ) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Sergey_Makov: file(relativePath: { eq: "team/Sergey_Makov.jpeg" }) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Ilya_Likhoshva: file(relativePath: { eq: "team/Ilya_Likhoshva.jpeg" }) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Serhii_Pashchenko: file(
        relativePath: { eq: "team/Serhii_Pashchenko.jpeg" }
      ) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Sophia_Gorban: file(relativePath: { eq: "team/Sophia_Gorban.jpeg" }) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Sergey_Glushkovsky: file(
        relativePath: { eq: "team/Sergey_Glushkovsky.jpg" }
      ) {
        base
        childImageSharp {
          fluid(maxWidth: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const photosArr = [
    data.Matvii_Sivoraksha.childImageSharp.fluid,
    data.Korney_Vasilchenko.childImageSharp.fluid,
    data.Vlad_Horilyi.childImageSharp.fluid,
    data.Julian_Konchunas.childImageSharp.fluid,
    data.Anastasii_Kondaurova.childImageSharp.fluid,
    data.Sergey_Makov.childImageSharp.fluid,
    data.Ilya_Likhoshva.childImageSharp.fluid,
    data.Serhii_Pashchenko.childImageSharp.fluid,
    data.Sophia_Gorban.childImageSharp.fluid,
    data.Sergey_Glushkovsky.childImageSharp.fluid,
  ]
  return (
    <section className={"section"}>
      <div className="container">
        <div className="row v-center space-between flex-wrap">
          <div className={"heading"}>
            <h2>Our Team</h2>
            <p>Sed ut perspiciatis unde omnis iste natus error</p>
          </div>
          <div className={styles.wrapper}>
            {teamArr.map((person, index) => (
              <div className={styles.card} key={person.id}>
                <StyledCard type="reverse">
                  <div className={styles.content}>
                    <div className={styles.imageWrapper}>
                      <div className={styles.imageInner}>
                        <div className={styles.image}>
                          <Img
                            fluid={photosArr[index]}
                            alt={person.name} // only use section of the file extension with the filename
                          />
                        </div>
                      </div>
                    </div>
                    <h3>{person.name}</h3>
                    <p>{person.title}</p>
                    <ul className={styles.socials}>
                      {person.facebook ? (
                        <li>
                          <a
                            href={person.facebook}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FacebookIcon />
                          </a>
                        </li>
                      ) : null}
                      {person.linkedin ? (
                        <li>
                          <a
                            href={person.linkedin}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <LinkedInIcon />
                          </a>
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </StyledCard>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default TeamSection
