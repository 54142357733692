import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AwardsScreen from "../components/Home/06-Awards/awards"
import GFClutchSection from "../components/Services/05-GFClutch/gfClutch"
import ContactsScreen from "../components/Home/07-Contacts/contacts"
import TeamSection from "../components/AboutUs/01-Team/team"
import AboutUsScreen from "../components/AboutUs/00-Main/main"

const ServicesPage = () => (
  <Layout>
    <SEO
      title="About Madfish.Solutions | Software development company"
      description="Our team won several international blockchain hackathons (Tezos Hackathon and Uton Hack European Hackathon in 2019) and the biggest Ukrainian blockchain hackathon (Blockchain UA) for three times (ones in 2018 and twice in 2019)"
    />
    <AboutUsScreen />
    <AwardsScreen />
    <GFClutchSection />
    <TeamSection />
    <ContactsScreen />
  </Layout>
)

export default ServicesPage
