import React from "react"

// Styles
import styles from "./main.module.sass"

// Icons
import MainIcon from "../../../icons/aboutUsPage/main/about-us.svg"

const AboutUsScreen = () => {
  return (
    <section className={styles.section}>
      <div className={"container " + styles.container}>
        <div className="row v-center space-between flex-wrap">
          <div className={styles.content}>
            <h1>About Madfish</h1>
            <p>
              Software development company experienced in Blockchain and JS web.
              We offer full-cycle development (discovery phase and business
              analysis, software architecture and development, project
              management, QA, support and launching).
            </p>
            <p>
              Our team won several international blockchain hackathons (Tezos
              Hackathon and Uton Hack European Hackathon in 2019) and the
              biggest Ukrainian blockchain hackathon (Blockchain UA) for three
              times (ones in 2018 and twice in 2019). We have gained the trust
              of Tezos, Ukrainian government enterprises, and many other
              partners from around the world.
            </p>
            <h2>Our mission</h2>
            <p>
              Bring and accelerate the adoption of distributed and decentralized
              technologies for democratization and economic liberation.
            </p>
            <div className={styles.buttons}>
              <a
                href="https://www.linkedin.com/company/mad-fish-solutions/"
                target="_blank"
                rel="noreferrer noopener"
                className={"button button-primary " + styles.button}
              >
                <span className="content">Follow us on Linkedin</span>
              </a>
              <a
                href="https://github.com/madfish-solutions"
                target="_blank"
                rel="noreferrer noopener"
                className={"button button-secondary "}
              >
                <span className="content">Follow us on Github</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.image}>
        <MainIcon />
      </div>
    </section>
  )
}

export default AboutUsScreen
